<template>
    <v-main class="grey lighten-4">
        <div v-if="credentials && credentials['access_key_id']">
            <v-system-bar
                v-if="credentials['is_sandbox']"
                dark
                color="warning">
                <span v-html="$t('console.platforms.mturk.modeToolbar.sandbox')"></span>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="setActiveSandboxMode(false)"
                            :loading="loading">
                            <v-icon>mdi-swap-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <span
                        v-html="$t(
                            'console.platforms.mturk.modeChange',
                            { mode: $t('console.platforms.mturk.mode.production') }
                        )"
                    ></span>
                </v-tooltip>

                <v-spacer></v-spacer>

                <span>
                    Access Key ID:
                    <b>{{ credentials['access_key_id'] }}</b>
                </span>
            </v-system-bar>

            <v-system-bar
                v-else
                dark
                color="error">
                <span v-html="$t('console.platforms.mturk.modeToolbar.production')"></span>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="setActiveSandboxMode(true)"
                            :loading="loading">
                            <v-icon>mdi-swap-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <span
                        v-html="$t(
                            'console.platforms.mturk.modeChange',
                            { mode: $t('console.platforms.mturk.mode.sandbox') }
                        )"
                    ></span>
                </v-tooltip>

                <v-spacer></v-spacer>

                <span>
                    AccessKeyId:
                    <b>{{ credentials['access_key_id'] }}</b>
                    ({{ $t('console.platforms.mturk.modeToolbar.balance') }}: <b>${{ credentials['account_balance']['available_balance'] }}</b>)
                </span>
            </v-system-bar>
        </div>
        <div v-else>
            <v-system-bar color="grey lighten-2">
                <span v-if="loading">
                    {{ $t('console.platforms.mturk.modeToolbar.loading') }}
                    <v-btn icon disabled loading></v-btn>
                </span>

                <span v-else>
                    {{ $t('console.platforms.mturk.modeToolbar.notSet') }}
                </span>
            </v-system-bar>
        </div>

        <keep-alive :exclude="['HIT-Create']">
            <router-view :duct="duct" :projectName="projectName" :credentials="credentials" :client="client"></router-view>
        </keep-alive>
    </v-main>
</template>
<script>
export default {
    data: () => ({
        credentials: null,
        loading: false
    }),
    props: ['duct', 'projectName', 'client'],
    methods: {
        async setActiveSandboxMode(val) {
            this.loading = true;
            await this.client.mturk.setActiveSandboxMode({ is_sandbox: val });
        }
    },
    created() {
        this.client.invokeOnOpen(async () => {
            this.client.mturk.on('getActiveCredentials', {
                success: (credentials) => {
                        this.loading = false;
                        this.credentials = credentials;
                    }
            });
            this.client.mturk.on(['setActiveCredentials', 'deleteCredentials', 'renameCredentials', 'setActiveSandboxMode'], {
                success: () => {
                    this.client.mturk.getActiveCredentials.send();
                }
            });

            this.client.mturk.getActiveCredentials.send();
        });
    }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease-in;
}

.fade-enter-active {
  transition-duration: 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
